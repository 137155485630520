import React, { Component } from 'react'
// import 'bulma/css/bulma.min.css'
import copyright from './resources/copyright.svg'

export default class footerComponent extends Component {
    render() {
        return (
            <div className="radio-link">
                <a href="https://www.radiogracanica.ba/" rel="noopener noreferrer" target="_blank">
                  radiogracanica.ba {'\u00A0'} </a><img className="radio-link-img-dimensions thisimg" src={copyright} alt="copyright" /> {new Date().getFullYear()}, {'\u00A0'} BKC Gračanica 💕
                <br />
                   | fm to net: by wirac.net | react app: by 🐵 | Enjoy Live Hi Quality 📻 Radio Gračanica Stream |
            </div>
        )
    }
}
