import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import AudioSpectrum from 'react-audio-spectrum'

import FooterComponent from './footerComponent'
import Shortcuts from './shortcutsComponent'

import './radio.css';

import playbtn from './resources/play.svg'
import pausebtn from './resources/pause.svg'
import logopic from './resources/logo.png'
import spectrumpic from './resources/spectrum.gif'
import spectrumNone from './resources/spectrum-none.png'
import mutedbtn from './resources/mute.svg'
import playingbtn from './resources/playing.svg'
import bufferingpic from './resources/buffering.svg'

class RadioPlayer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            playing: false,
            volume: 0.33,
            muted: false,
            buffering: false,
        };
    };

    handleKeyPress = (e) => {
        if (e.keyCode === 77) {
            this.setState({ muted: !this.state.muted })
        } else if (e.keyCode === 80) {
            this.setState({ playing: !this.state.playing })
        } else if (e.keyCode === 39) {
            if (this.state.volume < 0.99) {
                this.setState({ volume: ((this.state.volume) + 0.066) });
                if ((this.state.volume).toFixed(3) > 0.066) {
                    this.setState({ muted: false })
                }
            }
        } else if (e.keyCode === 37) {
            if ((this.state.volume).toFixed(2) > 0.066) {
                this.setState({ volume: (this.state.volume - 0.066) });

                if ((this.state.volume).toFixed(2) < 0.066) {
                    this.setState({ muted: true })
                }
            }
        } else if (e.keyCode === 66) {
            this.setState({ buffering: !this.state.buffering });
        }
    }
    playPause = () => {
        this.setState({ playing: !this.state.playing })
    }

    setVolume = e => {
        this.setState({ volume: parseFloat(e.target.value) });
        if (parseFloat(e.target.value) === 0) {
            this.setState({ muted: true })
        } else if (parseFloat(e.target.value) > 0) {
            this.setState({ muted: false })
        };
    }
    toggleMuted = () => {
        this.setState({ muted: !this.state.muted })
    }
    setBuffer = () => {
      //this.setState({ buffering: true });
        console.log("učitavam...");
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyPress);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
    }

    render() {
        const radioUrl = 'http://185.69.28.214:1234/broadwavehigh.mp3';
        const { playing, volume, muted, buffering } = this.state
        let radiostate;
        let showbufferingimg = false;


        if (playing && !muted) {
            if (buffering) {
                radiostate = "svira, a u isto vrijeme se učitava.";
                showbufferingimg = true;
            } else {
                radiostate = "svira radio 😍"
            }
        } else if (playing && muted) {
            if (buffering) {
                radiostate = "trebao bi svirati ali je zvuk isključen i učitava se.";
                showbufferingimg = true;
            } else {
                radiostate = "svira ~~~ ali je zvuk isključen."
            }
        } else if (!playing && !muted) {
            radiostate = "ne svira 😟"
        } else if (!playing && muted) {
            radiostate = "ne svira + zvuk je isključen 😵";
        }

        return (
            <div className="radio">

                <div className="rg-title">
                    <h5>Radio Gračanica <span className="lipstick">  ♥  87.6 & 95  ♥  </span> HQ Live Stream </h5>
                </div>

                <div>
                    <img src={logopic} alt="RG logo" className="set-logo-image-dimensions" />

                    <h4 className="yellow"> 87.6 {radiostate} {showbufferingimg ? <img className="right-floater" src={bufferingpic} alt="buff" height="20px" width="20px" /> : ''}</h4>
                </div>

                <Shortcuts />

                <img className="play-pause-img-dimensions add-some-margin cursor-to-hand" src={playing ? pausebtn : playbtn} alt="zaustavi" onClick={this.playPause} />

                <ReactPlayer
                    url={radioUrl}
                    playing={playing}
                    volume={volume}
                    muted={muted}
                    width='0'
                    height='0'
                    onError={e => console.log('onError', e)}
                />

                <div>
                    <img className="mute-btn-dimensions cursor-to-hand" src={muted ? mutedbtn : playingbtn} alt="glasnoća" onClick={this.toggleMuted} /> {'\u00A0'}
                    <input type='range' min={0} max={0.99} step={0.066} value={volume} onChange={this.setVolume} />
                    <label className="right-floater add-left-margin"> {'\u00A0'} {(volume * 100).toFixed(0)}</label>
                </div>


                {showbufferingimg ? <img className="spectrum-img-dimensions" src={spectrumNone} alt="spektar" /> : <img className="spectrum-img-dimensions" src={playing ? spectrumpic : spectrumNone} alt="spektar" />}

                <FooterComponent />
            </div>
        )
    }
}

export default RadioPlayer
