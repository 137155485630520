import React, { Component } from 'react'

import expand from './resources/expand.svg'
import close from './resources/close.svg'

export default class Shortcuts extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showcallout: true
        };
    };

    toogleExpand = () => {
        this.setState({ showcallout: !this.state.showcallout })

    }

    checkIfMobile = () => {
        const isMobile = window.innerWidth <= 500;
        if (isMobile) {
            this.toogleExpand();
        }
    }

    componentDidMount() {
        this.checkIfMobile();
    }


    render() {
        const { showcallout } = this.state
        return (
            <div className="callout">
                <div className="callout-header" onClick={this.toogleExpand}>
                    <span> Komande </span>
                    <img className="callout-header-image-dimensions" src={showcallout ? close : expand} alt="expand" />
                </div>
                {showcallout ?
                    <div className="speech-bubble callout-container">
                        <p><span>➡ </span> - pojačaj</p>
                        <p><span>⬅ </span> - smanji</p>
                        <p><span>P </span> - sviraj/zaustavi</p>
                        <p><span>M </span> - utišaj</p>
                        <p><span>B </span> - simuliraj učitavanje</p>
                    </div>
                    : ''}
            </div>
        )
    }
}
